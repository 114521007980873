const chainTags = [
  "Stairs",
  "Sandwich",
  "Turn Around",
  "GTR",
  "L-Shape",
  "Yayoi",
  "Sullen GTR",
  "Landslide",
  "Reversible",
  "Flat Stacking",
  "Submarine",
  "Persian Style",
  "Diving",
  "Kuma Stacking",
  "Aztec",
  "Kenny Style",
  "Raanet Meteor",
  "New GTR",
  "Misuken GTR",
  "GTGTR",
  "ETR",
  "LLR",
  "Pechura",
  "Zabuton",
  "Slug Folding",
  "Danshoku",
  "Sanagawa Turn Around",
  "Beetle",
  "Alpine Flower",
  "Multiple Stacking",
  "Tailing",
  "Avalanche",
  "Turukame",
  "Quicksand",
  "Turning",
  "Scatter",
  "Daruma Otoshi",
  "Insane Niagara",
  "Diving (Tail)",
  "Door Bolt",
  "2-Column Tail",
  "Saitou Special",
  "Tanaka Special",
  "Sagat Special",
  "Tom Special",
  "live Special",
  "Shiroma Special",
  "3-Column Tail",
  "deAlice",
  "EXE Special",
  "GTS Tail",
  "Collapsing Crevasse",
  "Thomson Tail",
  "Counter",
  "Holy Counter",
  "Evil Counter",
  "Double Counter",
  "Dam Counter",
  "Matsukane Counter",
  "Successive Counter",
  "Tanakacchi Style Counter",
  "Ino Style Counter",
  "Power Chain",
  "Dam Stacking",
  "Thorn",
  "Hellfire",
  "Killer Ice",
  "Mille-feuille",
  "L-Murder",
  "Haunted House",
  "Kamaitachi",
  "Kameitachi",
  "Rising Dragon",
  "Two-on-Two Stacking",
  "Same-Color Chaining",
  "Kenny Formula",
  "Ghost Chain",
]

export default chainTags
