import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ChainsimFloater from "../components/chainsim-floater"
import DrillCategory from "../components/drill-category"
import allDrills from "../../scripts/drill_list.json"
import chainTags from "../components/chainsim-full/chainTags"

const toggleTagObject = {}
chainTags.forEach(tag => {
  toggleTagObject[tag] = true
})

const DrillPage = props => {
  // console.log(props.location)

  const [categoryList] = useState(
    Array.from(new Set(allDrills.map(drill => drill.category)))
  )
  const [authorList] = useState(
    Array.from(new Set(allDrills.map(drill => drill.author)))
  )
  const [drillList, setDrillList] = useState(allDrills)
  const [category, setCategory] = useState("Any")
  const [minLength, setMinLength] = useState(1)
  const [maxLength, setMaxLength] = useState(19)
  const [author, setAuthor] = useState("Any")
  const [tags, setTags] = useState(toggleTagObject)

  useEffect(() => {
    setDrillList(
      allDrills
        .filter(drill => {
          const hasCategory =
            category === "Any" ? true : drill.category === category
          const withinLength =
            drill.chainLength >= minLength && drill.chainLength <= maxLength
          const hasAuthor = author === "Any" ? true : drill.author === author
          const hasTag = drill.tags.some(tag => tags[tag])

          return hasCategory && withinLength && hasAuthor && hasTag
        })
        .sort((a, b) => a.chainLength - b.chainLength)
    )
  }, [category, minLength, maxLength, author, tags])

  return (
    <Layout location={props.location}>
      <SEO title="Chaining Drills" keywords={[`puyo.gg`, `drills`, `puyo`]} />
      <ChainsimFloater />
      <Container className="mt-4">
        <Row>
          <Col>
            <h1>Chaining Drills</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form>
              <Form.Row>
                <Form.Group as={Col} sm={4} controlId="category">
                  <Form.Label>Category</Form.Label>
                  <Form.Control
                    as="select"
                    value={category}
                    onChange={e => {
                      setCategory(e.target.value)
                    }}
                  >
                    <option value={"Any"} key={"Any"}>
                      Any
                    </option>
                    {categoryList.map(cat => (
                      <option value={cat} key={cat}>
                        {cat}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} sm={4} controlId="minLength">
                  <Form.Label>Min Length</Form.Label>
                  <Form.Control
                    type="number"
                    min="1"
                    max="19"
                    value={minLength}
                    onChange={e => {
                      const value = parseInt(e.target.value, 10)
                      if (!Number.isNaN(value)) {
                        if (value > maxLength) {
                          setMinLength(parseInt(value, 10))
                          setMaxLength(parseInt(value, 10))
                        } else if (value >= 1 && value <= 19) {
                          setMinLength(parseInt(value, 10))
                        }
                      }
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col} sm={4} controlId="maxLength">
                  <Form.Label>Max Length</Form.Label>
                  <Form.Control
                    type="number"
                    min="1"
                    max="19"
                    value={maxLength}
                    onChange={e => {
                      const value = parseInt(e.target.value, 10)
                      if (!Number.isNaN(value)) {
                        if (value < minLength) {
                          setMinLength(parseInt(value, 10))
                          setMaxLength(parseInt(value, 10))
                        } else if (value >= 1 && value <= 19) {
                          setMaxLength(parseInt(value, 10))
                        }
                      }
                    }}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} sm={4} controlId="author">
                  <Form.Label>Author</Form.Label>
                  <Form.Control
                    as="select"
                    value={author}
                    onChange={e => {
                      setAuthor(e.target.value)
                    }}
                  >
                    <option value={"Any"} key={"Any"}>
                      Any
                    </option>
                    {authorList.map(aut => (
                      <option value={aut} key={aut}>
                        {aut}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Col>
                  <Button
                    onClick={() => {
                      const newTagStates = { ...tags }
                      for (let tag in newTagStates) {
                        newTagStates[tag] = true
                      }
                      setTags(newTagStates)
                    }}
                  >
                    Select All Tags
                  </Button>
                  <Button
                    onClick={() => {
                      const newTagStates = { ...tags }
                      for (let tag in newTagStates) {
                        newTagStates[tag] = false
                      }
                      setTags(newTagStates)
                    }}
                  >
                    Remove All Tags
                  </Button>
                </Col>
              </Form.Row>

              <Form.Row>
                {chainTags.map((tag, i) => (
                  <Form.Check
                    inline
                    label={tag}
                    type="checkbox"
                    key={tag}
                    id={`tag-${i}-${tag}`}
                    checked={tags[tag]}
                    onChange={e => {
                      const newTagStates = { ...tags }
                      newTagStates[tag] = !tags[tag]
                      setTags(newTagStates)
                    }}
                  />
                ))}
              </Form.Row>
            </Form>
          </Col>
        </Row>
      </Container>
      <DrillCategory drills={drillList} />
    </Layout>
  )
}

export default DrillPage
